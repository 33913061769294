import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AndPipe } from './pipes/and.pipe';
import { ShortDatePipe } from './pipes/short-date.pipe';
import { DashedPipe } from './pipes/dashed.pipe';
import { IfConfirmedPipe } from './pipes/if-confirmed.pipe';
import {CustomerDuplicateComponent} from '../consent-centre-page/customer-consent/customer/customer-duplicate/customer-duplicate.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { IconButtonComponent } from './action-button/icon-button.component';
import {DataTableModule} from './data-table/data-table.module';
import { ModalComponent } from './modal/modal.component';
import { LinesWithLabelComponent } from './lines-with-label/lines-with-label.component';
import { CardComponent } from './card/card.component';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    declarations: [
        AndPipe,
        ShortDatePipe,
        DashedPipe,
        IfConfirmedPipe,
        CustomerDuplicateComponent,
        IconButtonComponent,
        ModalComponent,
        LinesWithLabelComponent,
        CardComponent
    ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatTooltipModule
  ],
  exports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AndPipe,
    ShortDatePipe,
    DashedPipe,
    IfConfirmedPipe,
    CustomerDuplicateComponent,
    IconButtonComponent,
    DataTableModule,
    ModalComponent,
    LinesWithLabelComponent,
    CardComponent
  ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [
        CookieService,
      ]
    };
  }

}
