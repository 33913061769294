import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../environments/environment';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root'
})
export class TokenGuard  {

  constructor(
    private cookieService: CookieService,
    private tokenService: TokenService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.cookieService.get(environment.token.token)
    console.log('token', token)


    console.log('--- is IE ---', /MSIE \d|Trident.*rv:/.test(navigator.userAgent));
    console.log('origin', window.location.origin);
    console.log('host', window.location.host);
    console.log('href', window.location.href);
    console.log('hostname', window.location.hostname);

    if (localStorage.getItem('consent_centre_access_token') === 'null' ||
      localStorage.getItem('consent_centre_access_token') === 'undefined') {
      console.log('Token was invalid (null / undefined)');
      localStorage.removeItem('consent_centre_access_token');
    }

    if (token !== null && token !== '' && token) {
      this.tokenService.setToken(token);
      return true;
    } else {
      if (this.tokenService.hasToken()) {
        return true;
      } else {
        window.open('/session-expired', '_self');
        return false;
      }
    }
  }

  isValidNewLocation(newLocation: string): boolean {
    return newLocation && newLocation.length > 10;
  }

  generateBackendRedirectUrl(baseUrl, tecCustomerId, apiToken): string {
    if (tecCustomerId.startsWith('https://') || tecCustomerId.startsWith('http://')) {
      return null;
    }
    console.log('generateBackendRedirectUrl', baseUrl + tecCustomerId + '?token=' + apiToken);
    return baseUrl + tecCustomerId + '?token=' + apiToken;
  }
}
