import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TokenGuard} from './token.guard';
import {ErrorPage, ErrorPageComponent} from "./error-page/error-page.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'consent-center'
  },
  {
    path: 'consent-center',
    canActivate: [TokenGuard],
    loadChildren: () => import('./consent-centre-page/consent-centre-page.module')
      .then(m => m.ConsentCentrePageModule)
  },
  {
    path: 'session-expired',
    component: ErrorPageComponent,
    data: ErrorPage.sessionExpired
  },
  {
    path: 'customer-unavailable',
    component: ErrorPageComponent,
    data: ErrorPage.customerUnavailable
  },
  {
    path: 'not-found',
    component: ErrorPageComponent,
    data: ErrorPage.notFound
  },
  {
    path: 'no-salesperson-found',
    component: ErrorPageComponent,
    data: ErrorPage.noSalesperson
  },
  {
    path: '**',
    component: ErrorPageComponent,
    data: ErrorPage.notFound
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
