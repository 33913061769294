const SERVER_BASE_URL = 'https://service.tcc-integration.toyota.itsd-consulting.services';
const SERVER_BASE_URL_FILE = 'https://file.tcc-integration.toyota.itsd-consulting.services';

export const environment = {
  production: false,
  logEnabled: true,
  country: 'DE',
  appStoreLink: 'https://apps.apple.com/app/tec-upload/id1535161622',
  playStoreLink: 'https://play.google.com/store/apps/details?id=de.itsdconsulting.documentcenter',
  auth: {
    access_token: 'consent_centre_access_token'
  },
  token: {
    token: 'token',
    refreshToken: 'Refresh-token'
  },
  endpoints: {
    websocketBroadcastUrl: 'wss://service.tcc-integration.toyota.itsd-consulting.services:443/websocket/broadcast',
    changePreferredDealer: `${SERVER_BASE_URL}/customer/{tecCustomerId}/change-preferred-dealer`,
    sendChangePreferredDealerRequest: `${SERVER_BASE_URL}/customer/{tecCustomerId}/send-change-preferred-dealer-request`,
    denyOutdated: `${SERVER_BASE_URL}/customer-consent/{tecCustomerId}/deny-outdated`,
    apiHeader: `${SERVER_BASE_URL}/api-header`,
    CONSENT: {
      updateCustomerConsentById: `${SERVER_BASE_URL}/customer-consent/{id}`,
      confirmConsentByTecCustomerId: `${SERVER_BASE_URL}/consent/confirm/tecCustomerId/{tecCustomerId}/{sendConfirmInfoEmail}`,
      deleteConsentByTecCustomerId: `${SERVER_BASE_URL}/consent/delete/tecCustomerId/{tecCustomerId}`,
      denyConsentByTecCustomerId: `${SERVER_BASE_URL}/consent/deny/tecCustomerId/{tecCustomerId}/{sendConfirmInfoEmail}`,
      printConsentByTecCustomerId: `${SERVER_BASE_URL}/consent/print/{tecCustomerId}`,
      uploadFile: `${SERVER_BASE_URL_FILE}/attachment/{tecCustomerId}`,
      downloadFile: `${SERVER_BASE_URL_FILE}/attachment/download/{attachmentId}`,
      customConfirmExtendedConsentAccess: `${SERVER_BASE_URL}/consent/custom/confirm/tecCustomerId/{tecCustomerId}`,
      deleteTemporaryConsentByTecCustomerId: `${SERVER_BASE_URL}/consent/custom/delete/customerId/{tecCustomerId}`,
      startMailConsentByTecCustomerId: `${SERVER_BASE_URL}/consent/{tecCustomerId}/start-mail-consent`,
      mailConsentByTecCustomerId: `${SERVER_BASE_URL}/consent/{tecCustomerId}/mail`,
      diSigConsentByTecCustomerId: `${SERVER_BASE_URL}/consent/{tecCustomerId}/digSig`
    },
    CUSTOMER: {
      getCustomerDataComparison: `${SERVER_BASE_URL}/customer-duplicate/{tecCustomerId1}/{tecCustomerId2}`,
      createTempByPass: `${SERVER_BASE_URL}/customer-duplicate/create-temp-bypass/{tecCustomerId}`,
      saveAsSameCustomer: `${SERVER_BASE_URL}/customer-duplicate/save-same/{tecCustomerId1}/{tecCustomerId2}`,
      getCustomerById: `${SERVER_BASE_URL}/customer/{tecCustomerId}`,
      changeBrands: `${SERVER_BASE_URL}/customer/{tecCustomerId}/brands`,
      getCustomerByIdFromWebsocket: `${SERVER_BASE_URL}/customer/{tecCustomerId}/websocket`,
      customerVerification: `${SERVER_BASE_URL}/customer/verification/{tecCustomerId}`,
      customerConfirmation: `${SERVER_BASE_URL}/customer/confirmation/{tecCustomerId}`,
      findVerification: `${SERVER_BASE_URL}/customer/findVerification/{tecCustomerId}`,
      getCustomerEmails: `${SERVER_BASE_URL}/customer/emails/{tecCustomerId}`,
      searchCustomers: `${SERVER_BASE_URL}/customer/search`,
      digSigLink: `${SERVER_BASE_URL}/consent/digSigWebsite/{consentId}`,
      digSigRequestLink: `${SERVER_BASE_URL}/consent/digSigRequestWebsite/{tecCustomerId}/{token}`,
    },
    DEALER: {
      LoadDealerProperties: `${SERVER_BASE_URL}/dealer/{id}`,
      UpdateDealerMissingProperties: `${SERVER_BASE_URL}/dealer/{id}`,
    },
    REPORT: {
      getReport: `${SERVER_BASE_URL}/report/since/{since}/{dealerNumber}/{brandFilter}/{preferredDealerFilter}`,
    },
    OTHERS: {
      logout: `${SERVER_BASE_URL}/logout`
    }
  },
  themes: {
    forceTheme: null,
    toyota: {
      logo: '/assets/icons/logo-toyota.png',
      cssVars: {
        '--primary': '#eb0a1e',
        '--primary-hover': '#ce0000',
        '--background': 'url(/assets/images/backgrounds/background01.jpg)',
        '--fontfamily': 'toyota_rg, \'Open Sans\', sans-serif',
        '--fontfamily-rg': 'toyota_rg',
        '--fontfamily-bd': 'toyota_bd',
        '--primary-btn-color': '#ffffff',
        '--primary-btn-color-hover': '#ffffff',
      }
    },
    lexus: {
      logo: '/assets/icons/logo-lexus.png',
      cssVars: {
        '--primary': '#1a1a1a',
        '--primary-hover': '#646464',
        '--background': 'url(/assets/images/backgrounds/background_lexus.jpg)',
        '--fontfamily': 'Nobel, \'Open Sans\', sans-serif',
        '--fontfamily-rg': 'Nobel',
        '--fontfamily-bd': 'Nobel Bold',
        '--primary-btn-color': '#ffffff',
        '--primary-btn-color-hover': '#ffffff',
      }
    }
  },
  fileUpload: {
    allowedContentTypes: 'image/jpeg,image/png,image/gif,image/bmp,image/x-bmp,image/x-ms-bmp,image/tiff,image/heic,application/pdf,application/vnd.ms-outlook',
    allowedFileExtensions: [
      '.jpg',
      '.jpeg',
      '.png',
      '.gif',
      '.bmp',
      '.tiff',
      '.heic',
      '.pdf',
      '.msg'
    ]
  },
  appAuthEnvironmentId: 'tec-2-de',
  acceptedNotifications: 'ACC_NF_BY_ID',
  userlaneKey: 'djg7l6l84m'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
