import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from './data-table.component';
import { DataTableHeaderComponent } from './data-table-header/data-table-header.component';
import { DataTableFooterComponent } from './data-table-footer/data-table-footer.component';
import { DataTablePaginationComponent } from './data-table-pagination/data-table-pagination.component';
import { DataTableColumnComponent } from './data-table-column/data-table-column.component';
import { PaginatePipe } from './pipes/paginate.pipe';

@NgModule({
    declarations: [
      DataTableComponent,
      DataTableHeaderComponent,
      DataTableFooterComponent,
      DataTablePaginationComponent,
      DataTableColumnComponent,
      PaginatePipe,
    ],
  exports: [
    DataTableComponent,
    DataTableColumnComponent,
  ],
    imports: [
        CommonModule
    ]
})
export class DataTableModule { }
